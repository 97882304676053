<template>
  <div class="j_export__">
    <component 
      ref="target"
      mode-export
      :is="exComponent"
      :filters="exFilters"
      :target="exTarget"
    />
  </div>
</template>
<script>
import PDF from '@/mixins/pdf.mixin'
import JServiceComponents from '@/components/JServiceComponentTargets'
import { TokenCheckService }  from "@/services"
import __C from '../../primitives/_constant_'
import __M from 'moment'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: "export",
  mixins: [
    PDF
  ],
  components: {
    ...JServiceComponents
  },
  watch: {
    '$route.params': {
      handler() {
        this.callComponent()
      },
      deep: true
    }
  },
  created() {
    this.tokenCheckService = new TokenCheckService()
    this.callComponent()
  },
  data: () => ({
    tokenCheckService: null,
    exComponent: '',
    exFilters: null,
    exTarget: null,
  }),
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setSelectItems'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signout']),
    callComponent() {
      // api url example
      // http://localhost:8080/#/export/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN3aGFuIiwiZXhwIjoxNjgzMTAzMzQ3fQ.TVkh2yLbMgqgaKeOsYUT2QoOoUGAbdm0MOR5IYbkhlg/{"component":"ServicePageSvg","filter":{"filters":{"SEICODE_L1":"111300","TOGGLE":"EN","infoRequired":"true"},"iFilters":{"filterString":"","inputFilter":""}},"target":{"code":"Page","type":"export","id":1946,"no":""}}/null
    
      try{

        let token = this.$route.params.token
        let request_ = JSON.parse(this.$route.params.action)
        let items = this.$route.params.items
        let data = `{"sn":877,"id":"tester","password":"","userName":"tester","email":"tester","position":"Developer","office":"JGS INC.","department":"","officePhone":"","cellPhone":"","userPermit":"SYS_ADMIN","groupPermit":"","approval":"APPROVED","regDate":"2020-07-31T05:32:42.253Z","hit":"0","comment":"","token":"${token}","permitName":"","approvalName":""}`
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, data)
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.TOKEN, token)
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.TIMEOUT, __M().format('YYYY-MM-DD HH:mm:ss'))
        
        // For Local Test
        // this.tokenCheckService.tokenCheckLocal(token).then(res => {
        this.tokenCheckService.tokenCheck(token).then(res => {
          if (res) {
            this.exComponent = request_.component
            this.exFilters = request_.filter
            this.exTarget = request_.target
            setTimeout(() => { if(this.$refs.target.run) {
              this.setSelectItems(items)
              this.$refs.target.run() 
            }})
            setTimeout(() => {
              this.signout()
            }, 60000)            
          }else {
            this.signout()
            // this.$router.push('/InvalidAccess')
          }
        })
        // alert('token: ' + token)
        // this.exComponent = request_.component
        // this.exFilters = request_.filter
        // this.exTarget = request_.target
        // setTimeout(() => { if(this.$refs.target.run) this.$refs.target.run() })
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.j_export__ {
  display: inline-block;
}
</style>
