import { PdfApi } from './api'

export default class PdfService {
    
  constructor() {
    this.pdfApi = new PdfApi()
  }

  create(params) {
    return new Promise((resolve) => {
      this.pdfApi.create(params).then(res => {
        resolve(res)
      })
    })
  }
}
