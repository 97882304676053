import PdfService from '../services/pdf.service'

export default {
  data: () => ({
    pdfService: null
  }),
  created() {
    this.pdfService = new PdfService()
  },
  methods: {
    exportPDF() {
      this.loading = true

      // Blob ---
      // this.pdfService.create().then((res) => {
      this.pdfService.create().then((data) => {
        if(!data || typeof data != 'string') {
          console.warn('data empty!')
          this.loading = false
          return null
        }

        // decode base64 string, remove space for IE compatibility
        // var binary_ = atob(data.replace(/\s/g, ''))

        let binary_ = atob(data)
        let len = binary_.length

        // console.log('File Size:', Math.round(binary_.length / 1024), 'KB');
        // console.log('PDF Version:', binary_.match(/^.PDF-([0-9.]+)/)[1]);
        // console.log('Create Date:', binary_.match(/<xmp:CreateDate>(.+?)<\/xmp:CreateDate>/));
        // console.log('Modify Date:', binary_.match(/<xmp:ModifyDate>(.+?)<\/xmp:ModifyDate>/));
        // console.log('Creator Tool:', binary_.match(/<xmp:CreatorTool>(.+?)<\/xmp:CreatorTool>/));

        let buffer = new ArrayBuffer(len)
        let view = new Uint8Array(buffer)
        for (var i = 0; i < len; i++) {
          view[i] = binary_.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"               
        var blob = new Blob( [view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);

        // // let blob_ = new Blob([res])                                                   // responseType: 'blob'
        // let blob_ = new Blob([res], { type: 'application/json', encoding: 'UTF-8' })     // responseType: 'arraybuffer', { 'Content-Type': 'application/json' }
        // let url = window.URL.createObjectURL(blob_)
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.pdf')
        document.body.appendChild(link)
        link.click()

        // let fileName = res.headers["content-disposition"].split("filename=")[1];
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
        //     window.navigator.msSaveOrOpenBlob(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
        // } else {
        //     const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', res.headers["content-disposition"].split("filename=")[1]);
        //     document.body.appendChild(link);
        //     link.click();
        // }

        this.loading = false

      }).catch((error) => console.log(error))
    }
  }
}